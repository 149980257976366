<script setup lang="ts">
const { t } = useT();
const loginGuard = useLoginGuard();
const { open } = useAppModals();
const isGuest = useIsGuest();
const { data: bonusesData } = useBonusesData();

const handleFortuneWheelClick = () => {
	loginGuard({
		success: () => {
			open("LazyOModalDailyWheel");
		},
		fail() {
			open("LazyOModalSignup");
		}
	});
};
</script>

<template>
	<MMobNavBanner @click="handleFortuneWheelClick">
		<AText :modifiers="['semibold']" class="text-cannes">
			{{ t("mobNavBar.wheel") }}
		</AText>
		<MWheelSpinnerWheel class="anim-wheel" :active="bonusesData?.dailyWheel?.available || isGuest" />
	</MMobNavBanner>
</template>

<style lang="scss" scoped>
.anim-wheel {
	right: 16px;
	top: -18px;
	z-index: 1;
}
</style>
